import React, { createContext, ReactNode, useCallback, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { PublicNode } from '../../__generated__/api'
import api from '../services/api'
import { flattenNodes } from '../utils/nodes'

import { useApi } from './useApi'
// eslint-disable-next-line import/no-cycle -- TODO fix circular dependency: useSearchTarget > useNodes > useOhc > useSearchTarget
import { useOHC } from './useOHC'

type NodesContextType = {
  nodes: PublicNode[]
  flattenedNodes: PublicNode[]
  getNodeById(id?: string): { node: PublicNode | null; pending: boolean }
}

const NodesContext = createContext<NodesContextType>({
  nodes: [],
  flattenedNodes: [],
  getNodeById: () => ({ node: null, pending: true }),
})

export const NodesProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { i18n } = useTranslation()
  const { ohcAllowedStatus, isOHCSide } = useOHC()
  const getOhcNodes = isOHCSide && ohcAllowedStatus === 'allowed'
  const { data: allNodes, pending: allNodesPending } = useApi(
    api.v1.allNodes,
    { lang: i18n.language, isOhc: getOhcNodes },
    []
  )

  const flattenedNodes = useMemo(() => flattenNodes(allNodes), [allNodes])

  const getNodeById = useCallback(
    (id?: string) => {
      if (!id) {
        return { node: null, pending: false }
      }

      return {
        node: flattenedNodes.find((node) => node.id === id) ?? null,
        pending: allNodesPending,
      }
    },
    [flattenedNodes, allNodesPending]
  )

  const memoized = useMemo(
    () => ({
      nodes: allNodes,
      flattenedNodes,
      getNodeById,
    }),
    [allNodes, flattenedNodes, getNodeById]
  )

  return <NodesContext.Provider value={memoized}>{children}</NodesContext.Provider>
}

export const useNodes = () => {
  return useContext(NodesContext)
}

export const useNode = (
  nodeId: string | undefined
): { node: PublicNode | null; pending: boolean } => {
  const { getNodeById } = useNodes()

  return getNodeById(nodeId)
}
