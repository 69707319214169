import { useState, useMemo } from 'react'

import { useDeepCompareEffect } from './useDeepCompare'
import useMobileRelay, { RelayEvent, RelayMessage } from './useMobileRelay'

interface Props {
  enabled: boolean
  onClose?(): void
  onBack?(): void
}

const useMobileClose = ({ enabled, onClose, onBack }: Props) => {
  const [prevMessage, setPrevMessage] = useState<RelayMessage | null>(null)
  const { message, postMessage } = useMobileRelay()
  const memoizedNow = useMemo(() => Date.now(), [])

  useDeepCompareEffect(() => {
    if (
      enabled &&
      message &&
      message.event === RelayEvent.PopupOpen &&
      message !== prevMessage &&
      message.ts > memoizedNow
    ) {
      setPrevMessage(message)
    }
  }, [message])

  useDeepCompareEffect(() => {
    if (prevMessage?.value === false) {
      if (onBack) {
        onBack()
        // A hack to circumvent Android bug where the back button is switched to booking close on back
        postMessage(RelayEvent.HeaderState, 'modal')
      } else if (onClose) {
        onClose()
      }
    }
  }, [prevMessage])
}

export default useMobileClose
