import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Black, Gray600 } from '@mehilainen/design-system-tokens/colors'
import { EllipsisH, ExclamationTriangle } from '@mehilainen/mds-customer/icons'
import { AutocompleteRenderOptionState } from '@mui/material'
import { Box } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { NodeAutocompleteResult, PublicNode, SupportedLanguage } from '../../../__generated__/api'
import api from '../../../common/services/api'
import useSearchTarget, { SearchTargetValue } from '../../../domain/search/hooks/useSearchTarget'
import { isOHCAtom } from '../../../state/common/atoms'
import {
  AppointmentSearchMode,
  callbackModalOpenAtom,
  controlSearchNodeModal,
  isUserSelectedNodeAtom,
  searchBreadcrumb,
  searchServiceModalOpenAtom,
} from '../../../state/search/atoms'
import { getDefaultNode } from '../../../state/search/selectors'
import { useApiOnDemand } from '../../hooks/useApi'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useNode } from '../../hooks/useNode'
import { useNodeSearch } from '../../hooks/useSearch'
import { breakpoint } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'
import { escapeRegExp } from '../../utils/text'
import AnchorButton from '../AnchorButton/AnchorButton'
import Avatar from '../Avatar/Avatar'
import { CenteredColumnFlex, CenteredRowFlex } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import Search from '../Search/Search'
import { SearchTreeItem } from '../Search/types'
import { convertSearchNode, convertSearchNodes, searchTreeItemIconProvider } from '../Search/utils'
import SelectInput from '../SelectInput/SelectInput'

import ListBlock from './ListBlock'

const SearchContainer = styled.div<{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? '0 0 24px 0' : '32px 24px 32px 24px')};
`

const NoteContainer = styled(CenteredColumnFlex)`
  text-align: center;
  gap: 24px;

  .MuiSvgIcon-root {
    width: 48px;
    height: 48px;
    color: ${Black};
  }
`

const NoteTitle = styled.div`
  font-size: 31px;
  line-height: 140%;
`

const FullWidthBox = styled(Box)`
  width: 100%;
`

const ResultsContainer = styled(CenteredRowFlex)`
  ${Avatar} {
    margin-right: ${scale(1.5)};
  }
  .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
    margin-right: ${scale(1.5)};
    color: ${Black};
  }
`

const TextContainer = styled(CenteredRowFlex)<{ isMobile: boolean }>`
  display: inline-block;
  width: 100%;
  font-size: ${(props) => (props.isMobile ? '14px' : '15px')};
  white-space: pre-wrap;
  @media (max-width: ${breakpoint.sm}px) {
    flex-wrap: wrap;
  }
`

const SearchItemNameHighlighted = styled.mark`
  background: none;
  font-weight: 700;
`

const PractitionerTitle = styled.span<{ isMobile: boolean }>`
  color: ${Gray600};
  margin-left: ${scale(2)};
  ${(props) =>
    props.isMobile &&
    css`
      font-size: 12px;
    `}
`

interface AutocompleteRowProps {
  re: RegExp
  state: AutocompleteRenderOptionState
  option: NodeAutocompleteResult
  action: (option: NodeAutocompleteResult) => void
  isMobile: boolean
}

const AutocompleteRow: React.FC<React.PropsWithChildren<AutocompleteRowProps>> = ({
  re,
  state,
  option,
  action,
  isMobile,
}) => (
  <FullWidthBox {...state} onClick={() => action(option)}>
    <ResultsContainer>
      {option.groupData && (
        <EllipsisH /* FIXME: not the correct icon, no LayerGroup in new design-system */ />
      )}
      {option.practitionerData && <Avatar image={option.practitionerData.image} />}
      <TextContainer isMobile={isMobile}>
        {option.keyword
          .split(re)
          .map((part, index) =>
            re.test(part) ? (
              <SearchItemNameHighlighted key={`search-item-name-highlight-${part}-${index}`}>
                {part}
              </SearchItemNameHighlighted>
            ) : (
              part
            )
          )}
        {option.practitionerData && option.practitionerData.title && (
          <PractitionerTitle isMobile={isMobile}>{option.practitionerData.title}</PractitionerTitle>
        )}
      </TextContainer>
    </ResultsContainer>
  </FullWidthBox>
)

const getIcon = (item: SearchTreeItem<PublicNode>) => {
  const icon = searchTreeItemIconProvider(item)
  return (
    <img
      src={icon}
      aria-hidden="true"
      style={{
        width: '28px',
        height: '28px',
        filter:
          'invert(23%) sepia(85%) saturate(1811%) hue-rotate(130deg) brightness(100%) contrast(101%)',
        shapeRendering: 'crispEdges',
      }}
    />
  )
}

interface Props {
  listedNodes: PublicNode[]
  favoriteNodes: PublicNode[]
  inputValue?: string | null
  selected: PublicNode | null
  onSelect: (service: PublicNode) => void
  onPractitionerSelect(practitionerId: number): void
}

const callbackItemId = AppointmentSearchMode.CALLBACK

const SearchSelect: React.FC<React.PropsWithChildren<Props>> = ({
  listedNodes,
  favoriteNodes,
  inputValue,
  selected,
  onSelect,
  onPractitionerSelect,
}) => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useRecoilState(searchServiceModalOpenAtom)
  const [breadcrumb, setBreadcrumb] = useRecoilState(searchBreadcrumb)
  const isOhc = useRecoilValue(isOHCAtom)
  const setIsUserSelectedNode = useSetRecoilState(isUserSelectedNodeAtom)
  const setControlNodeModal = useSetRecoilState(controlSearchNodeModal)
  const nodeSearch = useNodeSearch(isOhc)
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpenAtom)
  const isMobile = useIsMobile()
  const featureFlags = useFeatureFlags()
  const {
    data: searchResult,
    pending: searchPending,
    update: searchUpdate,
    reset: searchReset,
  } = useApiOnDemand(api.v1.searchNode, null)
  const { node: parentOfSelectedNode } = useNode(selected ? selected.parentId : undefined)
  const defaultNode = useRecoilValue(getDefaultNode)
  const { searchTarget } = useSearchTarget()

  useEffect(() => {
    searchReset()
  }, [open, searchReset])

  const searchKeywordCallback = useCallback(
    (id: string) => {
      searchUpdate({ lang: i18n.language as SupportedLanguage, keywordId: id, isOhc: isOhc })
    },
    [searchUpdate, i18n.language, isOhc]
  )

  const searchNodeCallback = useCallback(
    (id: string) => {
      searchUpdate({ lang: i18n.language as SupportedLanguage, nodeId: id, isOhc: isOhc })
    },
    [searchUpdate, i18n.language, isOhc]
  )

  const searchGroupCallback = useCallback(
    (keyword: string) => {
      searchUpdate({ lang: i18n.language as SupportedLanguage, keyword, isOhc: isOhc })
    },
    [searchUpdate, i18n.language, isOhc]
  )

  const selectAutocompleteOptionCallback = useCallback(
    (option: NodeAutocompleteResult) => {
      if (option.practitionerData) {
        onPractitionerSelect(option.practitionerData.id)
        setOpen(false)
      } else if (option.searchKeywordData) {
        searchKeywordCallback(option.searchKeywordData.id)
      } else if (option.nodeData) {
        searchNodeCallback(option.nodeData.id)
      } else if (option.groupData) {
        searchGroupCallback(option.keyword)
      }
    },
    [onPractitionerSelect, setOpen, searchKeywordCallback, searchNodeCallback, searchGroupCallback]
  )

  const selectAndCloseCallback = useCallback(
    (item: PublicNode) => {
      if (item.id === callbackItemId) {
        setCallbackModalOpen(true)
        setOpen(false)
        return
      }
      setIsUserSelectedNode(true)
      setControlNodeModal(['force', item.id])
      onSelect(item)
      setOpen(false)
    },
    [setIsUserSelectedNode, setControlNodeModal, onSelect, setOpen, setCallbackModalOpen]
  )

  const closeCallback = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  useEffect(() => {
    if (
      parentOfSelectedNode &&
      selected?.id !== defaultNode &&
      searchTarget.value === SearchTargetValue.Node
    ) {
      setBreadcrumb([
        convertSearchNode(parentOfSelectedNode, t('component.serviceSearch.secondaryGroupLabel')),
      ])
    } else {
      setBreadcrumb([])
    }
  }, [defaultNode, parentOfSelectedNode, searchTarget.value, selected?.id, setBreadcrumb, t])

  const noteProvider = (item: SearchTreeItem<PublicNode>) => {
    return (
      <NoteContainer>
        <ExclamationTriangle />
        <NoteTitle>{t('component.serviceSelect.noteTitle')}</NoteTitle>
        <div>{t('component.serviceSelect.noteText', { name: item.name.toLocaleLowerCase() })}</div>
        <div>
          <AnchorButton href="https://www.mehilainen.fi/tietoa-asiakkaalle/palvelunumerot">
            {t('component.serviceSelect.noteLink')}
          </AnchorButton>
        </div>
      </NoteContainer>
    )
  }
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callbackItem: SearchTreeItem<any> = {
    name: t('common.callback'),
    id: callbackItemId,
    item: {
      id: callbackItemId,
    },
  }

  const popularData = [
    ...convertSearchNodes(favoriteNodes, t('component.serviceSearch.secondaryGroupLabel')),
  ]
  if (featureFlags?.callbackRequestsEnabled) {
    popularData.push(callbackItem)
  }

  const allData = convertSearchNodes(
    listedNodes,
    t('component.serviceSearch.secondaryGroupLabel')
  ).sort((item, other) => item.name.localeCompare(other.name, i18n.language))

  const searchResultData = searchResult ? convertSearchNodes(searchResult) : null

  return (
    <>
      <SelectInput
        label={t('component.serviceSearch.label')}
        value={inputValue ?? selected?.name}
        onClick={() => setOpen(true)}
        dataCy="searchSelect-textField"
      />
      <ResponsiveModal
        open={open}
        onClose={closeCallback}
        fullWidth
        maxWidth="md"
        disableMobileClose
        omitDefaultPadding
      >
        <SearchContainer isMobile={isMobile}>
          <Search
            searchFieldLabel={t('component.serviceSearch.searchFieldLabel')}
            searchResult={searchResultData}
            popularData={popularData}
            allData={allData}
            breadcrumb={breadcrumb}
            onBreadcrumbChange={(crumb) => {
              setBreadcrumb(crumb)
              searchReset()
            }}
            onSelectAndClose={selectAndCloseCallback}
            onClose={closeCallback}
            noteProvider={noteProvider}
            searchPending={searchPending}
            searchIndex={nodeSearch}
            onAutocompleteOptionSelect={selectAutocompleteOptionCallback}
            renderAutocompleteOption={(props, option, state) => {
              const re = new RegExp(`(${escapeRegExp(state.inputValue)})`, 'gi')
              return (
                <li {...props}>
                  <AutocompleteRow
                    re={re}
                    option={option}
                    state={state}
                    action={selectAutocompleteOptionCallback}
                    isMobile={isMobile}
                  />
                </li>
              )
            }}
            getOptionLabel={(option) => option.keyword}
            optionListModifier={(options) => [
              ...options.filter((option) => !option.practitionerData),
              ...options.filter((option) => option.practitionerData),
            ]}
            backLabel={t('component.serviceSearch.backLabel')}
            useTracking
            open={open}
            renderItem={(item, onItemSelect, attr, dataset) => {
              return (
                <ListBlock
                  {...attr}
                  {...dataset}
                  hideAngle={!Boolean(item.children?.length ?? 0 > 0)}
                  title={item.name}
                  subtitle={item.caption}
                  onClick={onItemSelect}
                  icon={getIcon(item)}
                  size={isMobile ? 'small' : 'medium'}
                />
              )
            }}
          />
        </SearchContainer>
      </ResponsiveModal>
    </>
  )
}

export default SearchSelect
